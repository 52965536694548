import React, { Fragment, lazy } from "react";
import dynamic from "next/dynamic";
const LoginProvider = dynamic(() => import("../components/login/login"));


const Login = () => {
  return (
    <Fragment>
      <div className="overflow-auto">
        <div className="flex justify-center items-center p-2">
          <div className="bg-white rounded-lg border-1  p-4 w-full m-2 md:w-6/12 font-sans text-[14px]">
              <LoginProvider/>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
